import React from 'react'

const ReportIcon = ({ size }) => {
    return (
        <svg width={size ? size : "19"} height={size ? size : "20"} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.41667 13.1827H0.833333C0.373333 13.1827 0 12.8136 0 12.3588C0 11.904 0.373333 11.5349 0.833333 11.5349H4.97083L6.39 9.42977C6.55417 9.18589 6.84167 9.04006 7.135 9.06478C7.43167 9.0829 7.69583 9.25593 7.82917 9.51876L9.68667 13.1926L10.5567 11.9024C10.7117 11.6733 10.9717 11.5357 11.25 11.5357H15C15.46 11.5357 15.8333 11.9048 15.8333 12.3596C15.8333 12.8144 15.46 13.1836 15 13.1836H11.6958L10.2767 15.2887C10.1217 15.5185 9.86083 15.6553 9.58333 15.6553C9.56583 15.6553 9.54833 15.6553 9.53167 15.6537C9.235 15.6355 8.97083 15.4625 8.8375 15.1997L6.98 11.5258L6.11 12.8161C5.955 13.0451 5.695 13.1827 5.41667 13.1827ZM18.3333 8.63881V15.6545C18.3333 17.926 16.4642 19.7741 14.1667 19.7741H5.83333C3.53583 19.7741 1.66667 17.926 1.66667 15.6545C1.66667 15.1997 2.04 14.8306 2.5 14.8306C2.96 14.8306 3.33333 15.1997 3.33333 15.6545C3.33333 17.0173 4.455 18.1263 5.83333 18.1263H14.1667C15.545 18.1263 16.6667 17.0173 16.6667 15.6545V8.63881C16.6667 8.50451 16.66 8.37103 16.6475 8.23921H12.5C11.1217 8.23921 10 7.13021 10 5.76744V1.66679C9.86667 1.65443 9.73167 1.64784 9.59583 1.64784H5.83333C4.455 1.64784 3.33333 2.75684 3.33333 4.1196V9.06313C3.33333 9.51793 2.96 9.88705 2.5 9.88705C2.04 9.88705 1.66667 9.51793 1.66667 9.06313V4.1196C1.66667 1.84805 3.53583 0 5.83333 0H9.59583C11.1542 0 12.6183 0.599814 13.7208 1.68904L16.625 4.5604C17.7267 5.64962 18.3333 7.09808 18.3333 8.63881ZM16.1175 6.59137C15.9358 6.2791 15.7117 5.98743 15.4467 5.72625L12.5425 2.85489C12.2775 2.59288 11.9833 2.37124 11.6675 2.19163V5.76827C11.6675 6.22225 12.0417 6.59219 12.5008 6.59219H16.1183L16.1175 6.59137Z"
                fill="currentColor" />
        </svg>
    )
}

export default ReportIcon