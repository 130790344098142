import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { port } from '../../App'
import ClientCreation from './ClientCreation'
import RecuirementCrud from '../../Components/ClientComponents/RecuirementCrud'
import BackButton from '../../Components/MiniComponent/BackButton'
import RecuirterAssigningToRecuirment from '../../Components/ClientComponents/RecuirterAssigningToRecuirment'

const ParticularRequirementPage = () => {
    let { rid } = useParams()
    let [requirementDetails, setRequirementDetails] = useState()
    let getRequirementDetails = () => {
        axios.get(`${port}/root/cms/add-clients-requirements?requirement_id=${rid}`).then((response) => {
            console.log(response.data, 'reuirment');
            setRequirementDetails(response.data)
        }).catch((error) => {
            console.log(error, 'reuirment');
        })
    }
    useEffect(() => {
        getRequirementDetails()
    }, [rid])
    return (
        <div className='poppins ' >
            {/* Client Details */}
            <BackButton />
            {/* RequireAssigning */}
            <RecuirterAssigningToRecuirment cid={requirementDetails?.client_details?.id} rid={rid} />
            {/* Requirement details */}
            <h5 className='text-center  my-3 fw-normal ' >Requirement Details </h5>
            <RecuirementCrud rid={rid} rdata={requirementDetails} getData={getRequirementDetails} />
            <ClientCreation page='req' id={requirementDetails?.client_details?.id} />

        </div>
    )
}

export default ParticularRequirementPage