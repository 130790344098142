import React from 'react'

const ActionIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1667 8.33333C10.95 8.33333 8.33333 10.95 8.33333 14.1667C8.33333 17.3833 10.95 20 14.1667 20C17.3833 20 20 17.3833 20 14.1667C20 10.95 17.3833 8.33333 14.1667 8.33333ZM14.1667 18.3333C11.8667 18.3333 10 16.4667 10 14.1667C10 11.8667 11.8667 10 14.1667 10C16.4667 10 18.3333 11.8667 18.3333 14.1667C18.3333 16.4667 16.4667 18.3333 14.1667 18.3333ZM15.4167 12.0833C15.4167 12.775 14.8583 13.3333 14.1667 13.3333C13.475 13.3333 12.9167 12.775 12.9167 12.0833C12.9167 11.3917 13.475 10.8333 14.1667 10.8333C14.8583 10.8333 15.4167 11.3917 15.4167 12.0833ZM15 15V16.6667C15 17.125 14.625 17.5 14.1667 17.5C13.7083 17.5 13.3333 17.125 13.3333 16.6667V15C13.3333 14.5417 13.7083 14.1667 14.1667 14.1667C14.625 14.1667 15 14.5417 15 15ZM7.5 17.5C7.5 17.9583 7.125 18.3333 6.66667 18.3333H4.16667C1.86667 18.3333 0 16.4667 0 14.1667V4.16667C0 1.86667 1.86667 0 4.16667 0H8.96667C9.84167 0 10.7 0.358333 11.325 0.975L14.025 3.675C14.5833 4.23333 14.925 4.975 14.9917 5.75833C15.025 6.21667 14.6833 6.61667 14.225 6.65833C14.2 6.65833 14.1833 6.65833 14.1583 6.65833C13.725 6.65833 13.3667 6.325 13.325 5.89167C13.325 5.86667 13.325 5.85 13.325 5.825H10.8417C9.925 5.825 9.175 5.075 9.175 4.15833V1.68333C9.10833 1.68333 9.04167 1.66667 8.975 1.66667H4.16667C2.79167 1.66667 1.66667 2.79167 1.66667 4.16667V14.1667C1.66667 15.5417 2.79167 16.6667 4.16667 16.6667H6.66667C7.125 16.6667 7.5 17.0417 7.5 17.5Z"
                fill="currentColor" />
        </svg>

    )
}

export default ActionIcon