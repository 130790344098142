import React from 'react'

const HrIconFill = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66667 10C3.90917 10 1.66667 7.7575 1.66667 5C1.66667 2.2425 3.90917 0 6.66667 0C9.42417 0 11.6667 2.2425 11.6667 5C11.6667 7.7575 9.42417 10 6.66667 10ZM5.83333 17.5V12.5C5.83333 12.1067 5.51519 11.7579 5.13249 11.8484C2.19428 12.5432 0 15.1857 0 18.3333V19.1667C0 19.6267 0.373333 20 0.833333 20H6.66667C6.67435 20 6.67836 19.9879 6.67375 19.9817C6.1529 19.2869 5.83333 18.4333 5.83333 17.5ZM16.25 14.5C15.8358 14.5 15.5 14.1642 15.5 13.75C15.5 13.3358 15.8358 13 16.25 13C16.6633 13 17 13.3367 17 13.75C17 14.1633 16.6633 14.5 16.25 14.5ZM20 12.5V17.5C20 18.8808 18.8808 20 17.5 20H10C8.61917 20 7.5 18.8808 7.5 17.5V12.5C7.5 11.1192 8.61917 10 10 10H17.5C18.8808 10 20 11.1192 20 12.5ZM12.6667 11.6667C12.2983 11.6667 12 11.965 12 12.3333V13.5792C12 13.9934 11.6642 14.3292 11.25 14.3292C10.8358 14.3292 10.5 13.9934 10.5 13.5792V12.3333C10.5 11.965 10.2017 11.6667 9.83333 11.6667C9.465 11.6667 9.16667 11.965 9.16667 12.3333V17.6667C9.16667 18.035 9.465 18.3333 9.83333 18.3333C10.2017 18.3333 10.5 18.035 10.5 17.6667V16.4125C10.5 15.9983 10.8358 15.6625 11.25 15.6625C11.6642 15.6625 12 15.9983 12 16.4125V17.6667C12 18.035 12.2983 18.3333 12.6667 18.3333C13.035 18.3333 13.3333 18.035 13.3333 17.6667V12.3333C13.3333 11.965 13.035 11.6667 12.6667 11.6667ZM17.4442 15.4527C17.4442 15.453 17.4445 15.4531 17.4447 15.453C18.0469 15.0296 18.4175 14.2981 18.3167 13.485C18.185 12.4233 17.2083 11.6667 16.1392 11.6667H14.8333C14.465 11.6667 14.1667 11.965 14.1667 12.3333V17.6667C14.1667 18.035 14.465 18.3333 14.8333 18.3333C15.2017 18.3333 15.5 18.035 15.5 17.6667V16.2066C15.5 16.0004 15.6671 15.8333 15.8732 15.8333C16.0392 15.8333 16.1852 15.9429 16.2316 16.1023L16.7408 17.8525C16.8233 18.1375 17.0842 18.3333 17.3808 18.3333H17.4333C17.8808 18.3333 18.2017 17.9008 18.0708 17.4725L17.4674 15.4882C17.46 15.4638 17.4693 15.4383 17.49 15.4233C17.4742 15.4333 17.4642 15.4408 17.4443 15.4524C17.4442 15.4525 17.4442 15.4526 17.4442 15.4527Z"
                fill="currentColor" />
        </svg>

    )
}

export default HrIconFill