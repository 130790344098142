import React from 'react'

const EmployeeWhiteIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_154_875)">
                <path d="M10 10C12.7575 10 15 7.7575 15 5C15 2.2425 12.7575 0 10 0C7.2425 0 5 2.2425 5 5C5 7.7575 7.2425 10 10 10ZM10 1.66667C11.8383 1.66667 13.3333 3.16167 13.3333 5C13.3333 6.83833 11.8383 8.33333 10 8.33333C8.16167 8.33333 6.66667 6.83833 6.66667 5C6.66667 3.16167 8.16167 1.66667 10 1.66667ZM17.5 19.1667C17.5 19.6267 17.1275 20 16.6667 20C16.2058 20 15.8333 19.6267 15.8333 19.1667C15.8333 16.6508 14.2292 14.5083 11.9925 13.6917L10.7283 15.5883L11.6392 18.9133C11.7917 19.4683 11.3017 20.0008 10.6383 20.0008H9.36167C8.69833 20.0008 8.20917 19.4683 8.36083 18.9133L9.27167 15.5883L8.0075 13.6917C5.77083 14.5083 4.16667 16.6508 4.16667 19.1675C4.16667 19.6275 3.79333 20.0008 3.33333 20.0008C2.87333 20.0008 2.5 19.6275 2.5 19.1675C2.5 15.0325 5.865 11.6675 10 11.6675C14.135 11.6675 17.5 15.0317 17.5 19.1667Z"
                    fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_154_875">
                    <rect width={size ? size : "20"} height={size ? size : "20"} fill="currentColor" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EmployeeWhiteIcon