import React from 'react'

const DashboardIcon = ({ size }) => {
    return (
        <svg width={size ? size : "17"} height={size ? size : "13"} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M9.72222 6.25C9.72222 6.95764 9.50833 7.61389 9.14444 8.1625L7.14792 6.16597C7.01736 6.03542 6.94444 5.85903 6.94444 5.675V2.84792C8.52917 3.16944 9.72222 4.57014 9.72222 6.25ZM5.55556 5.9625V2.84792C3.78472 3.20764 2.50278 4.91458 2.82847 6.85278C3.05417 8.19583 4.09236 9.31319 5.41806 9.625C6.43889 9.86458 7.40417 9.64792 8.1625 9.14514L5.96181 6.94444C5.70139 6.68403 5.55556 6.33056 5.55556 5.9625ZM13.1944 2.77778H11.8056C11.4215 2.77778 11.1111 3.08889 11.1111 3.47222C11.1111 3.85556 11.4215 4.16667 11.8056 4.16667H13.1944C13.5785 4.16667 13.8889 3.85556 13.8889 3.47222C13.8889 3.08889 13.5785 2.77778 13.1944 2.77778ZM13.1944 5.55556H11.8056C11.4215 5.55556 11.1111 5.86667 11.1111 6.25C11.1111 6.63333 11.4215 6.94444 11.8056 6.94444H13.1944C13.5785 6.94444 13.8889 6.63333 13.8889 6.25C13.8889 5.86667 13.5785 5.55556 13.1944 5.55556ZM13.1944 8.33333H11.8056C11.4215 8.33333 11.1111 8.64444 11.1111 9.02778C11.1111 9.41111 11.4215 9.72222 11.8056 9.72222H13.1944C13.5785 9.72222 13.8889 9.41111 13.8889 9.02778C13.8889 8.64444 13.5785 8.33333 13.1944 8.33333ZM16.6667 3.47222V9.02778C16.6667 10.9424 15.109 12.5 13.1944 12.5H3.47222C1.55764 12.5 0 10.9424 0 9.02778V3.47222C0 1.55764 1.55764 0 3.47222 0H13.1944C15.109 0 16.6667 1.55764 16.6667 3.47222ZM15.2778 3.47222C15.2778 2.32361 14.3431 1.38889 13.1944 1.38889H3.47222C2.32361 1.38889 1.38889 2.32361 1.38889 3.47222V9.02778C1.38889 10.1764 2.32361 11.1111 3.47222 11.1111H13.1944C14.3431 11.1111 15.2778 10.1764 15.2778 9.02778V3.47222Z"
                fill="currentColor" />
        </svg >

    )
}

export default DashboardIcon