import React from 'react'

const AppliedListIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "19"} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 0H4.16667C1.86917 0 0 1.86917 0 4.16667V14.1667C0 16.4642 1.86917 18.3333 4.16667 18.3333H15.8333C18.1308 18.3333 20 16.4642 20 14.1667V4.16667C20 1.86917 18.1308 0 15.8333 0ZM18.3333 14.1667C18.3333 15.545 17.2117 16.6667 15.8333 16.6667H4.16667C2.78833 16.6667 1.66667 15.545 1.66667 14.1667V4.16667C1.66667 2.78833 2.78833 1.66667 4.16667 1.66667H15.8333C17.2117 1.66667 18.3333 2.78833 18.3333 4.16667V14.1667ZM15.8333 5C15.8333 5.46 15.46 5.83333 15 5.83333H9.16667C8.70667 5.83333 8.33333 5.46 8.33333 5C8.33333 4.54 8.70667 4.16667 9.16667 4.16667H15C15.46 4.16667 15.8333 4.54 15.8333 5ZM6.66667 5C6.66667 5.69 6.10667 6.25 5.41667 6.25C4.72667 6.25 4.16667 5.69 4.16667 5C4.16667 4.31 4.72667 3.75 5.41667 3.75C6.10667 3.75 6.66667 4.31 6.66667 5ZM15.8333 9.16667C15.8333 9.62667 15.46 10 15 10H9.16667C8.70667 10 8.33333 9.62667 8.33333 9.16667C8.33333 8.70667 8.70667 8.33333 9.16667 8.33333H15C15.46 8.33333 15.8333 8.70667 15.8333 9.16667ZM6.66667 9.16667C6.66667 9.85667 6.10667 10.4167 5.41667 10.4167C4.72667 10.4167 4.16667 9.85667 4.16667 9.16667C4.16667 8.47667 4.72667 7.91667 5.41667 7.91667C6.10667 7.91667 6.66667 8.47667 6.66667 9.16667ZM15.8333 13.3333C15.8333 13.7933 15.46 14.1667 15 14.1667H9.16667C8.70667 14.1667 8.33333 13.7933 8.33333 13.3333C8.33333 12.8733 8.70667 12.5 9.16667 12.5H15C15.46 12.5 15.8333 12.8733 15.8333 13.3333ZM6.66667 13.3333C6.66667 14.0233 6.10667 14.5833 5.41667 14.5833C4.72667 14.5833 4.16667 14.0233 4.16667 13.3333C4.16667 12.6433 4.72667 12.0833 5.41667 12.0833C6.10667 12.0833 6.66667 12.6433 6.66667 13.3333Z"
                fill="currentColor" />
        </svg>

    )
}

export default AppliedListIcon