import React from 'react'

const ClientIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2495 10C11.2495 11.8383 12.7445 13.3333 14.5827 13.3333C16.421 13.3333 17.9159 11.8383 17.9159 10C17.9159 8.16167 16.421 6.66667 14.5827 6.66667C12.7445 6.66667 11.2495 8.16167 11.2495 10ZM14.5827 8.33333C15.5019 8.33333 16.2493 9.08083 16.2493 10C16.2493 10.9192 15.5019 11.6667 14.5827 11.6667C13.6636 11.6667 12.9161 10.9192 12.9161 10C12.9161 9.08083 13.6636 8.33333 14.5827 8.33333ZM9.99958 6.66667C11.8378 6.66667 13.3328 5.17167 13.3328 3.33333C13.3328 1.495 11.8378 0 9.99958 0C8.16133 0 6.66639 1.495 6.66639 3.33333C6.66639 5.17167 8.16133 6.66667 9.99958 6.66667ZM9.99958 1.66667C10.9187 1.66667 11.6662 2.41417 11.6662 3.33333C11.6662 4.2525 10.9187 5 9.99958 5C9.08045 5 8.33299 4.2525 8.33299 3.33333C8.33299 2.41417 9.08045 1.66667 9.99958 1.66667ZM5.41644 13.3333C7.2547 13.3333 8.74964 11.8383 8.74964 10C8.74964 8.16167 7.2547 6.66667 5.41644 6.66667C3.57818 6.66667 2.08325 8.16167 2.08325 10C2.08325 11.8383 3.57818 13.3333 5.41644 13.3333ZM5.41644 8.33333C6.33557 8.33333 7.08304 9.08083 7.08304 10C7.08304 10.9192 6.33557 11.6667 5.41644 11.6667C4.49731 11.6667 3.74984 10.9192 3.74984 10C3.74984 9.08083 4.49731 8.33333 5.41644 8.33333ZM19.9992 17.5V20H18.3326V17.5C18.3326 16.7183 17.8026 16.0692 17.0735 15.8883L14.5877 18.7692L12.0687 15.8967C11.3529 16.0858 10.8329 16.7317 10.8329 17.4992V19.9992H9.16628V17.4992C9.16628 16.7175 8.63631 16.0683 7.908 15.8875L5.42144 18.7683L2.90238 15.8958C2.18658 16.085 1.6666 16.7308 1.6666 17.4983V19.9983H0V17.4983C0 15.7083 1.40494 14.2492 3.19903 14.1758L3.59735 14.1592L5.41227 16.2283L7.19637 14.1608L7.59135 14.1717C8.56298 14.1983 9.41294 14.6358 10.0021 15.3067C10.5829 14.6475 11.4162 14.2133 12.3653 14.175L12.7636 14.1583L14.5786 16.2275L16.3627 14.16L16.7576 14.1708C18.5759 14.2208 20 15.6817 20 17.4967L19.9992 17.5Z"
             fill="currentColor" />
        </svg>

    )
}

export default ClientIcon