import React from 'react'

const PayrollIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10_184)">
                <path d="M15.8333 0H4.16667C1.86917 0 0 1.86917 0 4.16667V15.8333C0 18.1308 1.86917 20 4.16667 20H15.8333C18.1308 20 20 18.1308 20 15.8333V4.16667C20 1.86917 18.1308 0 15.8333 0ZM18.3333 15.8333C18.3333 17.2117 17.2117 18.3333 15.8333 18.3333H4.16667C2.78833 18.3333 1.66667 17.2117 1.66667 15.8333V4.16667C1.66667 2.78833 2.78833 1.66667 4.16667 1.66667H15.8333C17.2117 1.66667 18.3333 2.78833 18.3333 4.16667V15.8333ZM13.3333 11.6667C13.3333 13.045 12.2117 14.1667 10.8333 14.1667V15C10.8333 15.4608 10.4608 15.8333 10 15.8333C9.53917 15.8333 9.16667 15.4608 9.16667 15V14.1667H8.94333C8.05417 14.1667 7.22417 13.6883 6.77833 12.9175C6.5475 12.5183 6.68417 12.0092 7.08167 11.7792C7.48083 11.5467 7.99 11.685 8.22 12.0825C8.36917 12.3408 8.64583 12.5 8.9425 12.5H10.8325C11.2925 12.5 11.6658 12.1267 11.6658 11.6667C11.6658 11.3517 11.44 11.085 11.1292 11.0333L8.595 10.6108C7.47667 10.425 6.66583 9.46667 6.66583 8.33333C6.66583 6.955 7.7875 5.83333 9.16583 5.83333V5C9.16583 4.54 9.53833 4.16667 9.99917 4.16667C10.46 4.16667 10.8325 4.54 10.8325 5V5.83333H11.0558C11.945 5.83333 12.775 6.3125 13.2208 7.08333C13.4517 7.48167 13.315 7.99083 12.9175 8.22167C12.5175 8.4525 12.0083 8.31583 11.7792 7.9175C11.63 7.66 11.3533 7.50083 11.0567 7.50083H9.16667C8.70667 7.50083 8.33333 7.875 8.33333 8.33417C8.33333 8.64917 8.55917 8.91583 8.87 8.9675L11.4042 9.39C12.5225 9.57583 13.3333 10.5342 13.3333 11.6675V11.6667Z" 
                fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_10_184">
                    <rect width={size ? size : "20"} height={size ? size : "20"} fill="currentColor" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PayrollIcon