import React from 'react'

const DashBoardIconFill = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "16"} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 0.599396H4.16667C1.86583 0.599396 0 2.44415 0 4.719V11.3104C0 13.5852 1.86583 15.43 4.16667 15.43H15.8333C18.1342 15.43 20 13.5852 20 11.3104V4.719C20 2.44415 18.1342 0.599396 15.8333 0.599396ZM5.66833 12.4301C4.0775 12.0601 2.83167 10.7344 2.56083 9.14098C2.17 6.84142 3.70833 4.81622 5.83333 4.38943V8.08471C5.83333 8.52139 6.00917 8.94077 6.32167 9.24974L8.9625 11.8607C8.05167 12.4573 6.89333 12.7143 5.66833 12.4301ZM10.14 10.6957L7.74333 8.32612C7.58667 8.17123 7.49917 7.96195 7.49917 7.74361V4.39025C9.40083 4.77173 10.8325 6.43358 10.8325 8.42664C10.8325 9.26622 10.5758 10.0448 10.1392 10.6957H10.14ZM16.6667 12.1343H14.1667C13.7067 12.1343 13.3333 11.7652 13.3333 11.3104C13.3333 10.8556 13.7067 10.4864 14.1667 10.4864H16.6667C17.1267 10.4864 17.5 10.8556 17.5 11.3104C17.5 11.7652 17.1267 12.1343 16.6667 12.1343ZM16.6667 8.8386H14.1667C13.7067 8.8386 13.3333 8.46949 13.3333 8.01468C13.3333 7.55988 13.7067 7.19076 14.1667 7.19076H16.6667C17.1267 7.19076 17.5 7.55988 17.5 8.01468C17.5 8.46949 17.1267 8.8386 16.6667 8.8386ZM16.6667 5.54292H14.1667C13.7067 5.54292 13.3333 5.1738 13.3333 4.719C13.3333 4.26419 13.7067 3.89508 14.1667 3.89508H16.6667C17.1267 3.89508 17.5 4.26419 17.5 4.719C17.5 5.1738 17.1267 5.54292 16.6667 5.54292Z"
                fill="currentColor" />
        </svg>

    )
}

export default DashBoardIconFill