import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { port } from '../../App'
import { HrmStore } from '../../Context/HrmContext'
import ClientRequirmentTable from '../../Components/Tables/ClientRequirmentTable'

const AllRequirementsPage = () => {
    let { setTopNav } = useContext(HrmStore)
    let [allRequirement, setAllRequirement] = useState()
    let getAllRequirement = () => {
        axios.get(`${port}/root/cms/add-clients-requirements`).then((response) => {
            console.log(response.data, 'recui');
            setAllRequirement(response.data)
        }).catch((error) => {
            console.log(error, 'recui');
        })
    }
    useEffect(() => {
        getAllRequirement()
        setTopNav('recuirter')
    }, [])
    return (
        <div>
            All requirements
            <ClientRequirmentTable data={allRequirement} />
        </div>
    )
}

export default AllRequirementsPage