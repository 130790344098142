import React from 'react'

const SortIcon = ({ size }) => {
    return (
        <svg width={size ? size : "14"} height={size ? size : "15"} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.31703 11.0669C7.56141 11.3113 7.56141 11.7063 7.31703 11.9506L4.63391 14.6337C4.39016 14.8775 4.07016 14.9994 3.75016 14.9994C3.43016 14.9994 3.11016 14.8775 2.86641 14.6337L0.183281 11.9506C-0.0610938 11.7063 -0.0610938 11.3113 0.183281 11.0669C0.427656 10.8225 0.822656 10.8225 1.06703 11.0669L3.12516 13.125V0.625C3.12516 0.279375 3.40516 0 3.75016 0C4.09516 0 4.37516 0.279375 4.37516 0.625V13.125L6.43328 11.0669C6.67766 10.8225 7.07266 10.8225 7.31703 11.0669ZM13.567 3.04938L10.8839 0.36625C10.397 -0.120625 9.60328 -0.120625 9.11641 0.36625L6.43328 3.04938C6.18891 3.29375 6.18891 3.68875 6.43328 3.93313C6.67766 4.1775 7.07266 4.1775 7.31703 3.93313L9.37516 1.875V14.375C9.37516 14.7206 9.65453 15 10.0002 15C10.3458 15 10.6252 14.7206 10.6252 14.375V1.875L12.6833 3.93313C12.8052 4.055 12.9652 4.11625 13.1252 4.11625C13.2852 4.11625 13.4452 4.055 13.567 3.93313C13.8114 3.68875 13.8114 3.29375 13.567 3.04938Z"
                fill="currentColor" />
        </svg>
    )
}

export default SortIcon