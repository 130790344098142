import React from 'react'

const EmployeeIcon = ({ size }) => {
    return (
        <svg width={size ? size : "15"} height={size ? size : "20"} viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50083 10C4.74333 10 2.50083 7.7575 2.50083 5C2.50083 2.2425 4.74333 0 7.50083 0C10.2583 0 12.5008 2.2425 12.5008 5C12.5008 7.7575 10.2583 10 7.50083 10ZM9.7625 12.0167L8.22667 14.325L9.77667 20H14.1675C14.6283 20 15.0008 19.6267 15.0008 19.1667C15.0008 15.8192 12.7958 12.9783 9.7625 12.0167ZM6.77417 14.325L5.23833 12.0167C2.205 12.9783 0 15.8192 0 19.1667C0 19.6267 0.373333 20 0.833333 20H5.22417L6.77417 14.325Z"
                fill="currentColor" />
        </svg>
    )
}

export default EmployeeIcon