import React from 'react';

const LeaveIcon = ({ size }) => {
    return (
        <svg width={size ? size : "22"} height={size ? size : "22"} viewBox="0 0 298 356" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M223.167 0H74.8333C33.9378 0 0.666626 33.2712 0.666626 74.1667V281.833C0.666626 322.729 33.9378 356 74.8333 356H223.167C264.062 356 297.333 322.729 297.333 281.833V74.1667C297.333 33.2712 264.062 0 223.167 0ZM74.8333 29.6667H223.167C247.701 29.6667 267.667 49.6323 267.667 74.1667H30.3333C30.3333 49.6323 50.299 29.6667 74.8333 29.6667ZM223.167 326.333H74.8333C50.299 326.333 30.3333 306.368 30.3333 281.833V103.833H267.667V281.833C267.667 306.368 247.701 326.333 223.167 326.333ZM223.167 163.167C223.167 171.369 216.521 178 208.333 178H89.6666C81.4786 178 74.8333 171.369 74.8333 163.167C74.8333 154.964 81.4786 148.333 89.6666 148.333H208.333C216.521 148.333 223.167 154.964 223.167 163.167ZM163.833 222.5C163.833 230.703 157.188 237.333 149 237.333H89.6666C81.4786 237.333 74.8333 230.703 74.8333 222.5C74.8333 214.297 81.4786 207.667 89.6666 207.667H149C157.188 207.667 163.833 214.297 163.833 222.5Z"
                fill="currentColor" />
        </svg>

    );
};

export default LeaveIcon;
