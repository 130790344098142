import React from 'react'

const HrIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6667 5C11.6667 2.2425 9.42417 0 6.66667 0C3.90917 0 1.66667 2.2425 1.66667 5C1.66667 7.7575 3.90917 10 6.66667 10C9.42417 10 11.6667 7.7575 11.6667 5ZM6.66667 8.33333C4.82833 8.33333 3.33333 6.83833 3.33333 5C3.33333 3.16167 4.82833 1.66667 6.66667 1.66667C8.505 1.66667 10 3.16167 10 5C10 6.83833 8.505 8.33333 6.66667 8.33333ZM5.79833 12.5042C5.93 12.945 5.67833 13.4092 5.2375 13.5408C3.135 14.1658 1.66667 16.1375 1.66667 18.3333V19.1667C1.66667 19.6267 1.29333 20 0.833333 20C0.373333 20 0 19.6267 0 19.1667V18.3333C0 15.405 1.95833 12.7775 4.7625 11.9433C5.2025 11.8125 5.66667 12.0633 5.79833 12.5042Z"
                fill="currentColor" />
            <path d="M16.25 14.5H15.5V13H16.25C16.6633 13 17 13.3367 17 13.75C17 14.1633 16.6633 14.5 16.25 14.5ZM20 12.5V17.5C20 18.8808 18.8808 20 17.5 20H10C8.61917 20 7.5 18.8808 7.5 17.5V12.5C7.5 11.1192 8.61917 10 10 10H17.5C18.8808 10 20 11.1192 20 12.5ZM12.6667 11.6667C12.2983 11.6667 12 11.965 12 12.3333V14.3292H10.5V12.3333C10.5 11.965 10.2017 11.6667 9.83333 11.6667C9.465 11.6667 9.16667 11.965 9.16667 12.3333V17.6667C9.16667 18.035 9.465 18.3333 9.83333 18.3333C10.2017 18.3333 10.5 18.035 10.5 17.6667V15.6625H12V17.6667C12 18.035 12.2983 18.3333 12.6667 18.3333C13.035 18.3333 13.3333 18.035 13.3333 17.6667V12.3333C13.3333 11.965 13.035 11.6667 12.6667 11.6667ZM17.4442 15.4533C18.0467 15.03 18.4175 14.2983 18.3167 13.485C18.185 12.4233 17.2083 11.6667 16.1392 11.6667H14.8333C14.465 11.6667 14.1667 11.965 14.1667 12.3333V17.6667C14.1667 18.035 14.465 18.3333 14.8333 18.3333C15.2017 18.3333 15.5 18.035 15.5 17.6667V15.8333H16.1533L16.7408 17.8525C16.8233 18.1375 17.0842 18.3333 17.3808 18.3333H17.4333C17.8808 18.3333 18.2017 17.9008 18.0708 17.4725L17.455 15.4475C17.4742 15.4333 17.475 15.4342 17.49 15.4233C17.4742 15.4333 17.4642 15.4408 17.4442 15.4525V15.4533Z"
                fill="currentColor" />
        </svg>
    )
}

export default HrIcon