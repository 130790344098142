import React from 'react'

const PerformanceIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_28_2596)">
                <path d="M15.5941 10H11.6666C11.2246 10 10.8007 9.82442 10.4881 9.51186C10.1756 9.1993 9.99996 8.77538 9.99996 8.33335V4.39502C10.0011 4.01167 9.91406 3.63319 9.74557 3.28886C9.57709 2.94452 9.33167 2.64354 9.0283 2.40918C8.74083 2.18153 8.40487 2.023 8.04639 1.94584C7.6879 1.86868 7.31647 1.87495 6.9608 1.96418C4.94051 2.46569 3.15191 3.64173 1.89077 5.29782C0.629642 6.9539 -0.0284727 8.99083 0.0252652 11.0717C0.0790031 13.1526 0.841375 15.1529 2.1863 16.7417C3.53123 18.3305 5.37815 19.4126 7.42163 19.8092C9.71922 20.249 12.098 19.7937 14.0708 18.5366C16.0437 17.2795 17.4613 15.3158 18.0333 13.0475C18.1229 12.6914 18.1294 12.3194 18.0524 11.9604C17.9754 11.6013 17.8169 11.2648 17.5891 10.9767C17.352 10.6737 17.0493 10.4285 16.7038 10.2593C16.3582 10.0901 15.9788 10.0015 15.5941 10ZM16.4175 12.64C16.0984 13.9249 15.4438 15.102 14.5205 16.0509C13.5973 16.9997 12.4386 17.6864 11.1629 18.0405C9.88723 18.3946 8.54039 18.4036 7.26013 18.0664C5.97987 17.7292 4.81215 17.0581 3.87639 16.1215C2.94063 15.185 2.27042 14.0167 1.93432 12.7362C1.59821 11.4556 1.60827 10.1088 1.96346 8.83344C2.31866 7.55806 3.00624 6.39992 3.95588 5.47747C4.90553 4.55502 6.08315 3.90136 7.3683 3.58335C7.47824 3.55588 7.59309 3.55454 7.70365 3.57943C7.81421 3.60432 7.9174 3.65475 8.00496 3.72668C8.10837 3.80552 8.19223 3.90712 8.25004 4.0236C8.30785 4.14008 8.33805 4.26831 8.3383 4.39835V8.33335C8.3383 9.2174 8.68949 10.0653 9.31461 10.6904C9.93973 11.3155 10.7876 11.6667 11.6716 11.6667H15.5983C15.7295 11.6672 15.8588 11.6975 15.9766 11.7552C16.0944 11.813 16.1975 11.8967 16.2783 12C16.3491 12.0888 16.3985 12.1928 16.4225 12.3038C16.4465 12.4148 16.4453 12.5299 16.4175 12.64Z"
                    fill="currentColor" />
                <path d="M19.7093 6.20501C19.3256 4.79458 18.5803 3.50877 17.5471 2.47483C16.5139 1.44089 15.2286 0.694673 13.8185 0.310014C13.6706 0.270272 13.5182 0.2501 13.3651 0.250014C13.1449 0.247922 12.9265 0.289487 12.7224 0.372308C12.5184 0.455129 12.3328 0.577563 12.1764 0.732536C12.0199 0.887509 11.8957 1.07195 11.811 1.2752C11.7262 1.47845 11.6826 1.69647 11.6826 1.91668V5.83335C11.6826 6.49639 11.946 7.13227 12.4148 7.60112C12.8837 8.06996 13.5196 8.33335 14.1826 8.33335H18.1151C18.3723 8.33087 18.6255 8.26913 18.855 8.15291C19.0845 8.0367 19.2841 7.86914 19.4383 7.66325C19.5925 7.45737 19.6972 7.2187 19.7442 6.9658C19.7912 6.71289 19.7792 6.45255 19.7093 6.20501ZM17.6276 6.66668H14.1793C13.9583 6.66668 13.7463 6.57888 13.59 6.4226C13.4337 6.26632 13.346 6.05436 13.346 5.83335L13.3393 1.92251C13.3454 1.9181 13.3526 1.9155 13.3601 1.91501H13.3818C14.5169 2.22425 15.551 2.82577 16.381 3.65955C17.2109 4.49333 17.8077 5.5302 18.1118 6.66668H17.6276Z"
                    fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_28_2596">
                    <rect width={size ? size : "20"} height={size ? size : "20"} fill="currentColor" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PerformanceIcon