import React, { useEffect } from 'react'

const RequirmentAssignRecruiterTable = ({ data }) => {
    useEffect(() => {
        console.log(data,'requirement');

    }, [])
    return (
        <div>
            <main className='table-responsive tablebg max-h-[50vh] ' >
                <table className='w-full ' >
                    <tr>
                        <th>SI NO </th>
                        <th>Recruiter Name  </th>
                        <th>Position Allocated </th>
                        <th>Candidate Assigned </th>
                        <th>Position Closed </th>
                    </tr>
                    {
                        data && data.map((obj, index) => (
                            <tr>
                                <td>{index + 1} </td>
                                <td>{obj?.recruiter?.Name} </td>
                                <td>{obj.position_count} </td>
                                <td>20 </td>
                                <td>{obj.closed_pos_count} </td>
                            </tr>
                        ))
                    }

                </table>

            </main>
        </div>
    )
}

export default RequirmentAssignRecruiterTable