import React from 'react'

const ReportIconFill = ({ size }) => {
    return (
        <svg width={size ? size : "18"} height={size ? size : "20"} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.668 5.83333V0.383333C11.4288 0.671667 12.1288 1.11583 12.7221 1.70833L15.6255 4.61333C16.2188 5.20583 16.663 5.90583 16.9513 6.66667H11.5013C11.0413 6.66667 10.668 6.2925 10.668 5.83333ZM17.3146 8.33333H11.5013C10.123 8.33333 9.0013 7.21167 9.0013 5.83333V0.02C8.86714 0.0108333 8.73297 0 8.59714 0H4.83464C2.53714 0 0.667969 1.86917 0.667969 4.16667V11.6667H3.97214L5.3913 9.5375C5.55547 9.29083 5.84297 9.14333 6.1363 9.16833C6.43297 9.18667 6.69714 9.36167 6.83047 9.6275L8.68797 13.3433L9.55797 12.0383C9.71297 11.8067 9.97297 11.6675 10.2513 11.6675H14.0013C14.4613 11.6675 14.8346 12.0408 14.8346 12.5008C14.8346 12.9608 14.4613 13.3342 14.0013 13.3342H10.6971L9.27797 15.4633C9.12297 15.6958 8.86213 15.8342 8.58464 15.8342C8.56714 15.8342 8.54963 15.8342 8.53297 15.8325C8.2363 15.8142 7.97214 15.6392 7.8388 15.3733L5.9813 11.6575L5.1113 12.9625C4.9563 13.1942 4.6963 13.3333 4.41797 13.3333H0.667969V15.8333C0.667969 18.1308 2.53714 20 4.83464 20H13.168C15.4655 20 17.3346 18.1308 17.3346 15.8333V8.7375C17.3346 8.60167 17.3238 8.4675 17.3146 8.33333Z" fill="currentColor" />
        </svg>

    )
}

export default ReportIconFill